/* src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  background-color: rgb(24 49 83);
  font-family: 'Bricolage Grotesque', sans-serif;
  color: rgb(24 49 83);


}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


.mr-2 {
  padding-right: 15px;
}


.headline {
  word-wrap: break-word; /* Ensures words will wrap onto the next line */
  white-space: normal; /* Ensures the text wraps normally */
  width: 100%; /* Ensures the element takes the full width of its container */
  color: rgb(24 49 83);
  justify-content: left;
  font-size: 40px;
  line-height: 40px;
 
}

.mb-6 {
  color: #0F9D58;
}

.mb-7 {
  color: #7511a0;
  font-weight: 600;
  font-size: larger;
}

.mb-8 {
  font-weight: 500;
  font-size: 16px;
  color: rgb(24 49 83);
  list-style: none;
}

.close-button, .dismiss-button, .sign-up-button,.sign-out-button {
  border: 2px solid rgb(24 49 83);
  box-shadow: 4px 4px 0 rgb(24 49 83);
  border-radius: 5px;
  margin: 10px 10px;
  margin-bottom: 30px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(24 49 83);
  transition: all 0.2s ease-in-out;
}

.close-button:active, .dismiss-button:active, .sign-up-button:active, .sign-out-button:active {
  box-shadow: 4px 4px 0 black;
  transform: translate(6px, 6px);
  color: aliceblue;
}

.close-button:active {
  background-color: rgb(66 133 244);
}

.dismiss-button:active, .sign-out-button:active  {
  background-color: rgb(219 68 55);
}

.sign-up-button:active {
  background-color: rgb(23, 146, 6);
}


/* src/App.css */
.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

