.logo-container {
    display: flex;
    justify-content: left;
    padding: 0px 15px;
  }
  
  .logo {
    max-width: 50px;
    padding: 15px;
  }

  .logo-text {
    padding-top: 8px;
    font-weight:900;
  }