.home-container {
    padding: 20px;
    background-color: #FFF;
    min-height: 100vh;
    position: relative;
  }
  
  .home-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    max-width: 100%;
  }
  
  .add-card-button {
    margin-top: 30px;
    font-size: 24px;
    padding: 15px 15px;
    background: green;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
  